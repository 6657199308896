<template>
  <div class="auth-page">
    <routeHeader selectedKey="authorization" />
    <div class="pd-section">
      <div class="pd-container" v-for="pd in productList" :key="pd.productId">
        <div class="pd-header">{{ pd.productName }}</div>
        <div class="pd-list" v-if="pd.orders.length">
          <div class="pd-list-item" v-for="(order, index) in pd.orders" :key="index">
            <div class="g-flex-row item-info">
              <img src="@i/tiktok.png" alt />
              <div>
                <p class="cl-bbb">抖音账号</p>
                <p>{{ order.advertiserCompanyName || '-' }}</p>
              </div>
            </div>
            <div class="item-info">
              <p class="cl-bbb">绑定时间</p>
              <p>{{ order.bindTime | fBindTime }}</p>
            </div>
            <div class="item-info">
              <p class="cl-bbb">有效期</p>
              <p v-if="order.bindType === 1">可绑定账号{{ order.number }}个</p>
              <p v-else>{{ order.expireTime | fBindTime }}</p>
            </div>
            <div class="item-info">
              <p class="cl-bbb">插件版本</p>
              <a-badge :color="order.productSkuName === '标准版' ? 'green' : order.productSkuName === '高级版' ? 'orange' : ''" :text="order.productSkuName || '-'" />
              <!-- <p>{{ order.productSkuName || '-' }}</p> -->
            </div>
            <div class="item-info">
              <a-button v-if="order.bindType === 1" type="primary" @click="onBindShow(pd.productId, order.orderId)">绑定</a-button>
              <div v-else>--</div>
              <!-- <a-button  v-else type="primary" ghost @click="onRechargeShow(pd.productId, order.advertiserCompanyId)">续费</a-button> -->
            </div>
          </div>
        </div>
        <div v-else class="empty-section">
          <img src="../../assets/images/blank.png" alt />
          暂无数据
        </div>
        <!-- <div class="pd-list-add" @click="onRechargeShow(pd.productId)">+ 购买{{ pd.productName }}</div> -->
      </div>
    </div>

    <!-- 续费/购买 -->
    <Recharge v-if="isRechargeShow" :defaultProductId="productId" :advertiserCompanyId="advertiserCompanyId" @ok="onRechargeOk" @cancel="onRechargeCancel" />
    <!-- 绑定 -->
    <a-modal :width="563" v-model="isBindShow" title="绑定账号" @ok="onBindOk">
      <!-- <div class="bind-form-item">
        <div class="label">账号:</div>
        <a-select style="width: 100%" placeholder="请选择抖音账号" v-model="accountId">
          <a-select-option
            v-for="account in accountList"
            :key="account.advertiserCompanyId"
            :value="account.advertiserCompanyId"
          >
            {{ account.advertiserCompanyName }}
            <span
              class="cl-bbb"
            >{{ account.isBind ? '-已绑定' : '' }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="bind-form-item">
        <div class="label"></div>
        <div class="link" @click="onBindNew">{{ linkText }}</div>
      </div>-->
      <div class="account-list">
        <div v-for="account in accountList" :key="account.advertiserCompanyId" :class="['account-item', accountId === account.advertiserCompanyId ? 'active' : '']" @click="accountId = account.advertiserCompanyId">{{ account.advertiserCompanyName }}</div>
      </div>
      <span @click="onBindNew" class="add-account-btn">
        <b>+</b> 添加一个新的抖音账号
      </span>
    </a-modal>
  </div>
</template>
<script>
import Recharge from '../components/Recharge'
import moment from 'moment'
export default {
  components: {
    Recharge
  },
  filters: {
    fBindTime: function (value) {
      if (!value) {
        return '-'
      }
      return moment(value).format('YYYY年MM月DD日')
    }
  },
  data () {
    return {
      isRechargeShow: false,
      advertiserCompanyId: '',
      isBindShow: false,
      productList: [],
      productId: '',
      orderId: 0,
      accountList: [],
      linkText: '绑定一个新的抖音帐号',
      accountId: undefined
    }
  },
  created () {
    this.getProductAuths()
    const { productId, orderId } = this.$route.query
    if (productId && orderId) {
      this.onBindShow(productId, orderId)
    }
  },
  methods: {
    onBindShow (productId, orderId) {
      this.isBindShow = true
      this.orderId = orderId
      this.productId = productId
      this.getTiktokCompanies()
    },
    onBindOk () {
      if (!this.accountId) {
        this.$message.warning('请选择一个抖音账号')
        return
      }
      this.bindLoading = true
      const params = {
        advertiserCompanyId: this.accountId,
        orderId: this.orderId
      }
      this.$api.auth.bindAuth(params)
        .then(res => {
          this.bindLoading = false
          this.isBindShow = false
          this.getProductAuths()
        })
        .catch(r => {
          this.bindLoading = false
        })
    },
    onBindNew () {
      this.$api.auth.getOAuthCode({
        productId: this.productId,
        orderId: this.orderId
      })
        .then(res => {
          const { code } = res
          const url = `https://qianchuan.jinritemai.com/openapi/qc/audit/oauth.html?app_id=1713472745535507&state=3|${code}&scope=%5B20120000%2C22000000%2C23000000%2C20110000%2C24000000%2C21000000%5D&material_auth=1&redirect_uri=https%3A%2F%2Ftfjl.zhujitech.com%2Faccount%2Foauth&rid=e9jonv9ool`
          window.open(url, '_blank')
        })
    },
    onRechargeShow (productId, advertiserCompanyId) {
      this.advertiserCompanyId = advertiserCompanyId
      this.isRechargeShow = true
      this.productId = productId
    },
    onRechargeOk () {
      this.isRechargeShow = false
      this.getProductAuths()
      // this.getTiktokCompanies()
    },
    onRechargeCancel () {
      this.isRechargeShow = false
    },
    getProductAuths () {
      this.$api.auth.productAuths().then(res => {
        this.productList = res || []
      })
    },
    getTiktokCompanies () {
      this.$api.company.getTiktokCompanies({ productId: this.productId })
        .then(res => {
          this.accountList = res || []
          // if (this.accountList.length !== 0) {
          //   this.accountId = this.accountList[0].advertiserCompanyId
          // }
        })
        .catch(r => {
          if (r === 1) {
            this.linkText = '当前账号抖音授权已过期，请重新授权'
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.empty-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #bfbfbf;
  padding: 30px 0;

  img {
    width: 62px;
    height: 40px;
    margin-bottom: 14px;
  }
}

.account-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;

  .account-item {
    margin-right: 15px;
    margin-bottom: 15px;
    height: 40px;
    // line-height: 40px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d7dadf;
    cursor: pointer;

    &.active {
      border: 1px solid #3d8fff;
      box-shadow: 0px 0px 13px 0px rgba(61, 143, 255, 0.35);
      border-radius: 4px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: 20px solid #3d8fff;
        border-top: 20px solid transparent;
      }

      &:after {
        content: "✓";
        position: absolute;
        right: 0px;
        bottom: -3px;
        color: #fff;
      }
    }
  }
}

.add-account-btn {
  cursor: pointer;
  vertical-align: middle;
}

.cl-bbb {
  color: #bbbbbb;
}

.auth-page {
  padding: 24px;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  display: flex;
  flex: 1;
}

.pd-section {
  background-color: #fff;
  width: 100%;
  padding: 24px;
}

.pd-container {
  width: calc(100% - 98px);
  margin-bottom: 24px;

  .pd-header {
    width: 100%;
    height: 44px;
    background: #fafafa;
    border: 1px solid #eeeeee;
    border-bottom: none;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border-radius: 4px 4px 0 0;
  }

  .pd-list {
    border-bottom: 1px solid #eee;
    .pd-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border: 1px solid #eee;
      border-bottom: none;

      img {
        width: 28px;
        height: 28px;
        margin-right: 16px;
      }

      .item-info {
        width: 20%;

        // text-align: center;
        &:nth-of-type(1) {
          width: 30%;
        }

        &:nth-of-type(4) {
          width: 10%;
        }

        &:nth-of-type(5) {
          width: 10%;
        }
      }
    }
  }

  .pd-list-add {
    width: 100%;
    height: 50px;
    background: #fafafa;
    border: 1px solid #eeeeee;
    border-top: 1px dashed #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: @cl-main;
    border-radius: 0 0 4px 4px;
    // &:hover {
    //   color: @cl-main;
    // }
  }
}

.bind-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  .label {
    min-width: 40px;
    text-align: right;
    margin-right: 24px;
  }

  .link {
    cursor: pointer;
    color: @cl-main;
    text-decoration: underline;
  }
}
</style>
