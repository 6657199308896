<template>
  <div class="recharge_page">
    <a-modal v-show="!isConfirmShow" :bodyStyle="bodyStyle" :width="680" v-model="visible" title="应用购买" @cancel="onCancelModal">
      <div class="g-flex-row">
        <div class="form_containe">
          <p class="form_label">选择应用</p>
          <div class="g-flex-row">
            <div v-for="pd in productList" :key="pd.productId" :class="['check_item', pd.productId === acAppId ? 'active' : '']" @click="onPdChange(pd.productId)">{{ pd.productName }}</div>
          </div>
          <p class="form_label">选择版本</p>
          <div class="g-flex-row">
            <div v-for="vertion in appDetail.productSkus" :key="vertion.productSkuId" :class="['check_item', vertion.productSkuId === acVersionId ? 'active' : '']" @click="onVertionChange(vertion.productSkuId)">
              {{ vertion.productSkuName }}
              <span class="tips">{{ vertion.dsc }}</span>
            </div>
          </div>
          <p class="form_label">选择时长</p>
          <div class="g-flex-row" v-if="vertionDetail.productSkuPrices">
            <div v-for="price in vertionDetail.productSkuPrices" :key="price.productSkuPriceId" :class="['check_item check_times', price.productSkuPriceId === acPriceId ? 'active' : '']" @click="onPriceChange(price.productSkuPriceId)">
              {{ price.showTime }}
              <p>
                ¥
                <span class="price">{{ price.price }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="desc_container">
          <div class="desc_header">
            <img src="@i/tiktok.png" alt />
            <div>
              {{ appDetail.productName }}
              <p class="color_bbb">{{ deadline }}到期</p>
            </div>
          </div>
          <div>
            <div class="g-flex-row-between mt15">
              <div>选择应用</div>
              <div>{{ appDetail.productName }}</div>
            </div>
            <div class="g-flex-row-between mt15">
              <div>选择版本</div>
              <div>{{ vertionDetail.productSkuName }}</div>
            </div>
            <div class="g-flex-row-between mt15">
              <div>购买时长</div>
              <div>{{ priceDetail.showTime }}</div>
            </div>
            <div class="g-flex-row-between mt15">
              <div>订购金额</div>
              <div>¥{{ priceDetail.price }}</div>
            </div>
            <div v-if="codeErrTips === '' && discountCode !== '' && discountAmount !== 0" class="g-flex-row-between mt15">
              <div>优惠金额</div>
              <div style="color:red;">¥{{ discountAmount }}</div>
            </div>
            <div class="g-flex-row-between mt15">
              <div>应付金额</div>
              <div>¥{{ payAmount }}</div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex;justify-content: space-between;">
          <div class="g-flex-row">
            优惠码：
            <a-input style="width:150px" placeholder="请输入" allowClear :maxLength="8" v-model="discountCode" @input.native="onInpDiscountChange" />&nbsp;&nbsp;
            <span v-if="codeErrTips" style="color:red;">{{ codeErrTips }}</span>
          </div>
          <a-button type="primary" :loading="confirmLoading" :disabled="codeErrTips !== '' || (discountCode.length !== 8 && discountCode.length !== 0) || checkLoading === true" @click="onConfirm">提交订单</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal :width="680" v-model="isConfirmShow" title="确认订单">
      <p>订单详情</p>
      <div class="order_header">
        <img src="@i/tiktok.png" alt />
        <div>
          {{ appDetail.productName }}
          <p class="color_bbb">{{ vertionDetail.productSkuName }}</p>
        </div>
      </div>
      <div class="order_desc">
        <div class="desc_item">
          <div class="g-flex-row-between mt15">
            <div>版本选择</div>
            <div class="font-bold">{{ vertionDetail.productSkuName }}</div>
          </div>
          <div class="g-flex-row-between mt15">
            <div>购买时长</div>
            <div class="font-bold">{{ priceDetail.showTime }}</div>
          </div>
          <div class="g-flex-row-between mt15">
            <div>到期时间</div>
            <div class="font-bold">{{ deadline }}</div>
          </div>
        </div>
        <div class="desc_item">
          <div class="g-flex-row-between mt15">
            <div>订单金额</div>
            <div class="font-bold">¥ {{ payAmount }}</div>
          </div>
        </div>
      </div>
      <p class="mt20">支付方式</p>
      <div class="g-flex-row pay_container">
        <div :class="['check_item', acPayType === 'wx' ? 'active' : '']" @click="() => { acPayType = 'wx' }">
          <img src="@i/wx.png" alt />
          微信支付
        </div>
        <div :class="['check_item', acPayType === 'alipay' ? 'active' : '']" @click="() => { acPayType = 'alipay' }">
          <img src="@i/alipay.png" alt />
          支付宝
        </div>
      </div>
      <template slot="footer">
        <div class="pay_footer">
          <div class="mr20">
            <p>
              应付金额:
              <span class="color_red font-bold">¥ {{ payAmount }}</span>
            </p>
            <p class="color_bbb">
              请您在
              <span class="color_red">{{ expiredTimeStr }}</span>前完成支付,否则订单将自动取消
            </p>
          </div>
          <a-button type="primary" @click="onSubmit" :loading="payLoading">提交订单</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 支付宝支付 -->
    <div v-html="payFormHtml"></div>
    <!-- 微信支付 -->
    <a-modal v-model="isWxShow" :closable="false" :footer="null" :width="284" :bodyStyle="bodyStyle" @cancel="onWxCancel">
      <div class="wx-modal">
        使用微信扫描二维码支付
        <div class="qrcode" ref="qrCodeUrl"></div>
        <p>
          请您在
          <span class="color_red">{{ expiredTimeStr }}</span>前完成支付
          <br />否则订单将自动取消
        </p>
        <div class="wx-footer">
          <div>
            <span @click="onPayOk">支付成功</span>
          </div>
          <div>
            <span @click="onWxCancel">返回</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import QRCode from 'qrcodejs2'
const PayEnum = {
  'alipay': 2,
  'wx': 1
}
export default {
  computed: {
    appDetail () {
      if (!this.acAppId && this.productList.length) {
        return this.productList[0]
      }
      const item = this.productList.find(pd => {
        return pd.productId === this.acAppId
      })
      if (item) {
        return item
      }
      return { productSkus: [] }
    },
    vertionDetail () {
      if (!this.acVersionId && this.appDetail.productSkus.length) {
        return this.appDetail.productSkus[0]
      }
      const item = this.appDetail.productSkus.find(vertion => {
        return vertion.productSkuId === this.acVersionId
      })
      if (item) {
        return item
      }
      return { productSkuPrices: [] }
    },
    priceDetail () {
      const item = this.vertionDetail.productSkuPrices.find(price => {
        return price.productSkuPriceId === this.acPriceId
      })
      if (item) {
        return item
      }
      return {}
    },
    deadline () {
      if (!this.priceDetail.day) {
        return ''
      }
      return moment().add(this.priceDetail.day, 'd').format('YYYY年MM月DD日')
    },
    expiredTimeStr () {
      if (!this.expiredTime) {
        return '--'
      }
      return moment(this.expiredTime).format('YYYY-MM-DD HH:mm')
    },
    payAmount () {
      const { priceDetail, discountAmount } = this
      if (this.codeErrTips !== '') {
        return priceDetail.price
      }
      return priceDetail.price - discountAmount > 0
        ? (priceDetail.price * 10000 - discountAmount * 10000) / 10000
        : 0
    },
    codeErrTips () {
      const { priceDetail, discountAmount, useAmount, discountCode } = this
      if (!discountCode) {
        return ''
      }
      if (this.codeErr) {
        return this.codeErr
      }
      if (priceDetail.price < useAmount) {
        return `不满足满${useAmount}减${discountAmount}条件`
      }
      return ''
    }
  },
  props: {
    advertiserCompanyId: {
      type: String,
      default: ''
    },
    defaultProductId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      bodyStyle: {
        padding: 0
      },
      visible: true,
      productList: [],
      acAppId: '',
      acVersionId: '',
      acPriceId: '',
      isConfirmShow: false,
      acPayType: 'alipay',
      orderId: '',
      payFormHtml: '',
      isWxShow: false,
      expiredTime: null, // 订单过期时间
      payLoading: false, // 支付状态
      confirmLoading: false, // 生成订单
      discountCode: '', // 优惠码
      codeErr: '',
      discountAmount: 0, // 优惠金额
      useAmount: 0, // 满减金额
      checkLoading: false
    }
  },
  methods: {
    handleClose () {
      this.isWxShow = false
      this.isConfirmShow = false
      this.visible = false
    },
    onCancelModal () {
      this.$emit('cancel')
      this.handleClose()
    },
    onPayOk () {
      this.$emit('ok')
      this.handleClose()
    },
    onWxCancel () {
      this.$refs.qrCodeUrl.innerHTML = ''
      this.isWxShow = false
    },
    onSubmit () {
      this.payLoading = true
      const { orderId } = this
      const params = {
        orderId,
        payType: PayEnum[this.acPayType],
        attach: window.location.href
      }
      this.$api.order.pay(params)
        .then(res => {
          this.payFormHtml = res.body
          if (res.payType === 2) { // 支付宝
            this.$nextTick(() => {
              document.forms[0].submit()
            })
          }
          if (res.payType === 1) { // 微信
            this.payLoading = false
            this.handleWePay(res.body)
          }
        })
        .catch(r => {
          this.payLoading = false
        })
    },
    onConfirm () {
      this.confirmLoading = true
      const { productSkuPriceId } = this.priceDetail
      const params = { productSkuPriceId }
      if (this.advertiserCompanyId) {
        params.advertiserCompanyId = this.advertiserCompanyId
      }
      // 优惠码
      if (this.discountCode) {
        params.discountCode = this.discountCode
      }
      this.$api.order.createdOrder(params)
        .then(res => {
          if (res.isSystemPay) {
            this.$message.success('订单支付成功')
            this.onPayOk()
            this.getProducts()
            return
          }
          this.isConfirmShow = true
          this.orderId = res.orderId
          this.expiredTime = res.expiredTime
          this.confirmLoading = false
        })
        .catch(r => {
          this.confirmLoading = false
          const msg = {
            1: '错误的商品id',
            2: '错误的advertiserCompanyId',
            3: '优惠码不存在',
            4: '优惠码已使用',
            5: '优惠码已过期',
            6: '优惠码已用完',
            7: '不满足使用条件'
          }
          this.$message.error(msg[r] || '提交失败')
        })
    },
    handleSelectChange () {
      this.acVersionId = this.appDetail.productSkus[0] ? this.appDetail.productSkus[0].productSkuId : ''
      this.acPriceId = this.vertionDetail.productSkuPrices[0] ? this.vertionDetail.productSkuPrices[0].productSkuPriceId : ''
    },
    onPdChange (productId) {
      this.acAppId = productId
      this.handleSelectChange()
    },
    onVertionChange (vertionId) {
      this.acVersionId = vertionId
      this.acPriceId = this.vertionDetail.productSkuPrices[0] ? this.vertionDetail.productSkuPrices[0].productSkuPriceId : ''
    },
    onPriceChange (productSkuPriceId) {
      this.acPriceId = productSkuPriceId
    },
    onInpDiscountChange () {
      console.log(408)
      if (this.discountCode.length === 8) {
        this.hendleDiscountCheck()
        return
      }
      this.codeErr = ''
      this.useAmount = 0
      this.discountAmount = 0
    },
    hendleDiscountCheck () {
      // this.discountCode
      this.checkLoading = true
      const { discountCode } = this
      this.$api.order.getDiscountCodeInfo({ discountCode })
        .then(res => {
          this.checkLoading = false
          this.codeErr = ''
          this.discountAmount = res.discountAmount
          this.useAmount = res.useAmount
        })
        .catch(r => {
          this.checkLoading = false
          const errTips = {
            3: '优惠码不存在',
            4: '优惠码已使用',
            5: '优惠码已过期',
            6: '优惠码已用完'
          }
          this.codeErr = errTips[r] || ''
        })
    },
    getProducts () {
      this.$api.product.getProducts()
        .then(res => {
          this.productList = res
          if (!this.acAppId) {
            this.acAppId = res[0].productId
          }
          this.handleSelectChange()
        }).catch(res => {
        })
    },
    handleWePay (text) {
      this.$nextTick(() => {
        const qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: text,
          width: 170,
          height: 170,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        console.log(qrcode)
      })
      this.isWxShow = true
    }
  },
  created () {
    this.getProducts()
  },
  watch: {
    defaultProductId: {
      handler (newValue) {
        if (newValue) {
          this.acAppId = newValue
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.color_bbb {
  color: #bbbbbb;
}

.color_red {
  color: #fe5526;
}

.h60 {
  height: 60px;
}

.recharge_page {
  box-sizing: border-box;
}

.form_containe {
  width: calc(100% - 242px);
  padding: 25px;
  height: 314px;
}

.desc_container {
  width: 242px;
  height: 314px;
  background-color: #f3f5f7;
  padding: 25px;

  .desc_header {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 24px;
    .g-flex-row;

    img {
      width: 34px;
      height: 34px;
    }
  }
}

.form_label {
  margin-top: 10px;
}

.check_item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  height: 40px;
  border: 1px solid #d7dadf;
  // box-shadow: 0px 0px 13px 0px rgba(61, 143, 255, 0.35);
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
  font-size: 13px;
  margin-top: 10px;

  &.active {
    border: 1px solid #3d8fff;
    box-shadow: 0px 0px 13px 0px rgba(61, 143, 255, 0.35);
    border-radius: 4px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-right: 20px solid #3d8fff;
      border-top: 20px solid transparent;
    }

    &:after {
      content: "✓";
      position: absolute;
      right: 0;
      font-size: 12px;
      bottom: -2px;
      color: #fff;
    }
  }

  .tips {
    color: #bbbbbb;
    margin-left: 6px;
  }
}

.check_times {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
  height: 60px;

  .price {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
  }
}

.order_header {
  background-color: #f3f5f7;
  border: 1px solid #e8e8e8;
  display: flex;
  padding: 10px 24px;
  margin-top: 10px;

  img {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
}

.order_desc {
  display: flex;
  border: 1px solid #e8e8e8;
  border-top: none;

  // padding: 0 24px;
  .desc_item {
    flex: 1;
    padding: 24px;
    padding-top: 0;

    &:nth-of-type(even) {
      border-left: 1px solid #e8e8e8;
    }
  }
}

.pay_container {
  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

.pay_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wx-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #333333;
  padding-top: 40px;

  .qrcode {
    width: 170px;
    height: 170px;
    margin-top: 14px;
    margin-bottom: 20px;

    img {
      padding: 6px; // 利用padding的特性，挤出白边
      box-sizing: border-box;
    }
  }

  p {
    text-align: center;
  }

  .wx-footer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    height: 50px;
    background: #f3f5f7;
    box-shadow: 0px 4px 8px 0px rgba(40, 40, 40, 0.1);
    border-radius: 4px;

    div {
      flex: 1;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      // &:nth-of-type(1) {
      //   border-right: 1px solid #ddd;
      // }
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
